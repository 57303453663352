<nz-layout>
  <nz-header>
    <div class="logo"><img src="./assets/gen_ai_big_logo.png" width="100%" /></div>
    <ul nz-menu nzTheme="dark" nzMode="horizontal">
      <li nz-menu-item routerLink="/workspaces">
        <span nz-icon nzType="home" nzTheme="outline"></span> Home
      </li>
      <li nz-menu-item routerLink="/dashboard">
        <span nz-icon nzType="dashboard" nzTheme="outline"></span> Weekly Insights
      </li>
      <li nz-menu-item (click)="openBackgroundDescriptionModal()">
        <span nz-icon nzType="picture" nzTheme="outline"></span> Background Description
      </li>
      <li nz-menu-item (click)="openBestRatioModal()">
        <span nz-icon nzType="percentage" nzTheme="outline"></span> Best Ratio
      </li>
      <li nz-menu-item (click)="openResizeImagesModal()">
        <span nz-icon nzType="shrink" nzTheme="outline"></span> Resize
      </li>
      <li nz-menu-item (click)="openMainSettingsDrawer()">
        <span nz-icon nzType="setting" nzTheme="outline"></span> Settings
      </li>
      <h1 nz-menu-item style="float: right">GenAI BIG 2.0</h1>
      <!--[nzWidth]="720"-->
      <nz-drawer
        [nzBodyStyle]="{overflow: 'auto'}"
        [nzMaskClosable]="true"
        [nzVisible]="isMainSettingsDrawervisible"
        nzTitle="Global Settings"
        [nzFooter]="footerTpl"
        (nzOnClose)="cancelEditedSettings()"
      >
        <form nz-form *nzDrawerContent [nzLayout]="'vertical'">
          <nz-form-item>
            <nz-form-label>Imagen Model</nz-form-label>
            <nz-form-control>
              <nz-select
                [ngModel]="(globalSettings$|async)?.imageGenModelVersion"
                (ngModelChange)="onImageGenModelVersionChange($event)"
                [ngModelOptions]="{standalone: true}"
              >
                @for (version of listImageGenModelVersions; track version) {
                <nz-option [nzValue]="version" [nzLabel]="version"></nz-option>
                }
              </nz-select>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label>Gemini Model</nz-form-label>
            <nz-form-control>
              <nz-select
                [ngModel]="(globalSettings$|async)?.geminiModelVersion"
                (ngModelChange)="onGeminiModelVersionChange($event)"
                [ngModelOptions]="{standalone: true}"
              >
                @for (version of listGeminiModelVersions; track version) {
                <nz-option [nzValue]="version" [nzLabel]="version"></nz-option>
                }
              </nz-select>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label>Number of Images per Run</nz-form-label>
            <nz-form-control>
              <nz-slider
                [ngModel]="(globalSettings$|async)?.numberOfImages"
                (ngModelChange)="onNumberOfImagesChange($event)"
                [ngModelOptions]="{standalone: true}"
                [nzMin]="minimumNumberOfImages"
                [nzMax]="maximumNumberOfImages"
                [nzStep]="1"
              >
              </nz-slider>
            </nz-form-control>
          </nz-form-item>
        </form>
        <ng-template #footerTpl>
          <div style="float: right">
            <button
              nz-button
              style="margin-right: 8px"
              nzType="primary"
              (click)="saveEditedSettings()"
            >
              Save
            </button>
            <button nz-button (click)="cancelEditedSettings()">Cancel</button>
          </div>
        </ng-template>
      </nz-drawer>
    </ul>
  </nz-header>
  <nz-content>
    <nz-breadcrumb>
      <nz-breadcrumb-item>GenAI BIG</nz-breadcrumb-item>
      @if(!currentlyViewingSpecificWorkspace) {
      <nz-breadcrumb-item
        ><span nz-icon nzType="folder-open" nzTheme="outline"></span> Workspaces</nz-breadcrumb-item
      >
      } @if(currentlyViewingSpecificWorkspace) {
      <nz-breadcrumb-item
        ><span nz-icon nzType="picture" nzTheme="outline"></span> Workspace</nz-breadcrumb-item
      >
      <nz-breadcrumb-item
        ><span nz-icon nzType="idcard" nzTheme="outline"></span>
        {{workspaceName$|async}}</nz-breadcrumb-item
      >
      }
      <!-- <nz-breadcrumb-item>List</nz-breadcrumb-item>
      <nz-breadcrumb-item>App</nz-breadcrumb-item> -->
    </nz-breadcrumb>
    <div class="inner-content">
      <router-outlet></router-outlet>
    </div>
  </nz-content>
  <nz-footer>© 2024 Google LLC - GenAI BIG 2.0. All Right Reserved.</nz-footer>
</nz-layout>

<nz-modal
  [(nzVisible)]="isBackgroundDescriptionModalVisible"
  nzTitle="Extract Image Background Description"
  (nzOnCancel)="closeBackgroundDescriptionModal()"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
>
  <ng-template #modalContent>
    <p>Image to extract the background description</p>
    <nz-upload
      nzAccept="image/png, image/jpeg, image/jpg"
      [nzSize]="10000"
      nzAction="/api/v2/upload?file_purpose=other&folder_name=describe_background"
      nzListType="picture-card"
      [(nzFileList)]="fileList"
      [nzShowButton]="fileList.length < 1"
      [nzPreview]="handlePreview"
      (nzChange)="this.fileUploadChanged($event)"
      [nzRemove]="removeImageToExtractBackgroundDescription"
    >
      <div>
        <span nz-icon nzType="plus"></span>
        <div style="margin-top: 8px">Upload</div>
        <p style="font-size: 10px">(max size 10MB)</p>
      </div>
    </nz-upload>
    <nz-modal
      [nzVisible]="previewVisible"
      [nzContent]="previewModalContent"
      [nzFooter]="null"
      (nzOnCancel)="previewVisible = false"
    >
      <ng-template #previewModalContent>
        <img [src]="previewImage" [ngStyle]="{ width: '100%' }" />
      </ng-template>
    </nz-modal>
  </ng-template>
  <ng-template #modalFooter>
    <button
      nz-button
      nzType="primary"
      (click)="extractBackgroundDescription(); closeBackgroundDescriptionModal()"
      [disabled]="!wereAllFilesUploaded()"
    >
      Submit
    </button>
    <button nz-button nzType="primary" (click)="closeBackgroundDescriptionModal()">Cancel</button>
  </ng-template>
</nz-modal>

<nz-modal
  [(nzVisible)]="isBestRatioModalVisible"
  nzTitle="Extract the best ratio from Image"
  (nzOnCancel)="closeBestRatioModal()"
  [nzContent]="ratioModalContent"
  [nzFooter]="ratioModalFooter"
>
  <!--      [nzBeforeUpload]="computeRatio"-->
  <ng-template #ratioModalContent>
    <p>Ratio from Image</p>
    <!-- TODO: Yale I am using this entry to prevent the uploader from being in error. -->
    <!-- I do the computation as client level, so there is probably a better way of doing? -->
    <nz-upload
      nzAccept="image/png, image/jpeg, image/jpg"
      [nzSize]="10000"
      nzAction="/api/v2/upload?file_purpose=other&folder_name=check_ratio"
      nzListType="picture-card"
      [(nzFileList)]="filesToGetRatio"
      [nzPreview]="handlePreview"
      [nzShowButton]="filesToGetRatio.length < 1"
    >
      <div>
        <span nz-icon nzType="plus"></span>
        <br />
        <p style="font-size: 10px">(max size 10MB)</p>
      </div>
    </nz-upload>
    <nz-modal
      [nzVisible]="previewVisible"
      [nzContent]="previewModalContent"
      [nzFooter]="null"
      (nzOnCancel)="previewVisible = false"
    >
      <ng-template #previewModalContent>
        <img [src]="previewImage" [ngStyle]="{ width: '100%' }" />
      </ng-template>
    </nz-modal>
  </ng-template>
  <ng-template #ratioModalFooter>
    <button
      nz-button
      nzType="primary"
      (click)="computeRatio(); closeBestRatioModal()"
      [disabled]="!wereAllFilesUploaded(filesToGetRatio)"
    >
      Submit
    </button>
    <button nz-button nzType="primary" (click)="closeBestRatioModal()">Cancel</button>
  </ng-template>
</nz-modal>

<nz-modal
  [(nzVisible)]="isResizeImagesModalVisible"
  nzTitle="Resize Images"
  (nzOnCancel)="closeResizeImagesModal()"
  [nzContent]="resizeImagesModalContent"
  [nzFooter]="resizeImagesModalFooter"
>
  <ng-template #resizeImagesModalContent>
    <p>Resize Images (with same size, max 10 images)</p>
    <nz-upload
      nzAccept="image/png, image/jpeg, image/jpg"
      [nzSize]="10000"
      nzAction="/api/v2/upload?file_purpose=other&folder_name=resize_image"
      nzListType="picture-card"
      nzType="drag"
      [nzMultiple]="true"
      [nzLimit]="10"
      [(nzFileList)]="filesToResize"
      [nzPreview]="handlePreview"
      [nzDisabled]="filesToResize.length >= 10"
      (nzChange)="imageToReSizeUploadChanged($event)"
    >
      <p class="ant-upload-drag-icon">
        <span nz-icon nzType="inbox"></span>
      </p>
      <p class="ant-upload-text">Click or drag images to this area to upload</p>
      <p class="ant-upload-hint">Support for a single or bulk upload (max size per image 10MB).</p>
    </nz-upload>
    <nz-modal
      [nzVisible]="previewVisible"
      [nzContent]="previewModalContent"
      [nzFooter]="null"
      (nzOnCancel)="previewVisible = false"
    >
      <ng-template #previewModalContent>
        <img [src]="previewImage" [ngStyle]="{ width: '100%' }" />
      </ng-template>
    </nz-modal>
    <nz-divider></nz-divider>
    <p>Width (px):</p>
    <nz-row>
      <nz-col nzSpan="12">
        <nz-slider
          [nzMin]="1"
          [nzMax]="initialWidth"
          [(ngModel)]="newWidth"
          (ngModelChange)="onUpdateHeight($event)"
        ></nz-slider>
      </nz-col>
      <div nz-col nzSpan="4">
        <nz-input-number
          [nzMin]="1"
          [nzMax]="initialWidth"
          [ngStyle]="{ marginLeft: '16px' }"
          [(ngModel)]="newWidth"
          (ngModelChange)="onUpdateHeight($event)"
        ></nz-input-number>
      </div>
    </nz-row>
    <br />
    <p>Height (px):</p>
    <nz-row>
      <nz-col nzSpan="12">
        <nz-slider
          [nzMin]="1"
          [nzMax]="initialHeight"
          [(ngModel)]="newHeight"
          (ngModelChange)="onUpdateWidth($event)"
        ></nz-slider>
      </nz-col>
      <nz-col nzSpan="4">
        <nz-input-number
          [nzMin]="1"
          [nzMax]="initialHeight"
          [ngStyle]="{ marginLeft: '16px' }"
          [(ngModel)]="newHeight"
          (ngModelChange)="onUpdateWidth($event)"
        ></nz-input-number>
      </nz-col>
    </nz-row>
  </ng-template>
  <ng-template #resizeImagesModalFooter>
    <button
      nz-button
      nzType="primary"
      (click)="resizeImages(); closeResizeImagesModal()"
      [disabled]="!(wereAllFilesUploaded(filesToResize) && allImagesSameSize && isResized())"
    >
      Submit
    </button>
    <button nz-button nzType="primary" (click)="closeResizeImagesModal()">Cancel</button>
  </ng-template>
</nz-modal>

import {Component, DestroyRef, inject} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {NavigationEnd, Router} from '@angular/router';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {NzUploadChangeParam, NzUploadFile} from 'ng-zorro-antd/upload';
import {Observable, from, of} from 'rxjs';
import {filter, map, shareReplay, switchMap} from 'rxjs/operators';
import {v4 as uuidv4} from 'uuid';
import {GeminiModelVersionEnum} from './models/gemini-model-version-enum';
import {GlobalSettings} from './models/global-settings';
import {ImageGenModelVersionEnum} from './models/image-gen-model-version-enum';
import {ApiService} from './services/api.service';
import {BusinessLogicService} from './services/business-logic.service';
import {IDBService} from './services/idb.service';

// TODO: move to a common util file. or remove if not needed
function getBase64(file: File): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

/** The root component. */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent {
  destroyRef = inject(DestroyRef);
  // isCollapsed = false;
  protected isMainSettingsDrawervisible = false;
  protected workspaceName$: Observable<string | undefined> = of('');
  protected currentlyViewingSpecificWorkspace = false;
  protected readonly defaultNumberOfImages = 10;
  protected readonly minimumNumberOfImages = 5;
  protected readonly maximumNumberOfImages = 50;
  protected imageToExtractBackgroundDescriptionUUID = '';
  protected isBackgroundDescriptionModalVisible = false;
  protected isBestRatioModalVisible = false;
  protected isResizeImagesModalVisible = false;
  private readonly allowedRatios = new Set([
    [1, 1],
    [16, 9],
    [4, 3],
    [3, 4],
    [9, 16],
  ]);

  protected filesToResize: NzUploadFile[] = [];
  protected newWidth = 1;
  protected newHeight = 1;
  protected initialWidth = 1;
  protected initialHeight = 1;
  protected allImagesSameSize = true;

  protected openBackgroundDescriptionModal() {
    this.isBackgroundDescriptionModalVisible = true;
    this.imageToExtractBackgroundDescriptionUUID = uuidv4();
  }

  protected openResizeImagesModal() {
    this.isResizeImagesModalVisible = true;
    this.newWidth = 1;
    this.newHeight = 1;
    this.initialWidth = 1;
    this.initialHeight = 1;
    this.allImagesSameSize = true;
    this.filesToResize = [];
  }

  protected openBestRatioModal() {
    this.isBestRatioModalVisible = true;
    this.filesToGetRatio = [];
  }

  protected closeBestRatioModal() {
    this.isBestRatioModalVisible = false;
    this.filesToGetRatio = [];
  }

  protected closeResizeImagesModal() {
    this.isResizeImagesModalVisible = false;
    this.filesToResize = [];
  }

  protected closeBackgroundDescriptionModal() {
    this.isBackgroundDescriptionModalVisible = false;
    console.log('closing the modal for background description.');
    this.fileList = [];
  }

  protected resizeImages() {
    console.log('Call resize logic');
    console.log(this.filesToResize);

    const urls = this.filesToResize.map((r) => r.response.url ?? '');

    this.bls.resizeImages(urls, this.newWidth, this.newHeight).subscribe({
      next: (result) => {
        console.log(result);
      },
      error: (err) => console.log(err),
    });

    // this.apiService.resizeImage(
    //   this.filesToResize[0].response.url, this.newWidth, this.newHeight)
    //   .subscribe({
    //     next: (result) => {console.log(result)},
    //     error: err => console.log(err)
    //   })

    //TODO: call the backend logic and return a zip file containing
    // all resized images zipped.
  }

  private getImageDimensions(
    file: NzUploadFile,
  ): Promise<{width: number; height: number}> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          resolve({width: img.width, height: img.height});
        };
        img.onerror = (error) => {
          reject(error);
        };
        img.src = event.target?.result as string;
      };
      reader.readAsDataURL(file.originFileObj!);
    });
  }

  private getSuggestedAspectRatio(width: number, height: number): string {
    let minDistanceToRatio = Infinity;
    let bestRatio = [0, 0];
    const imageRatio = width / height;

    for (const ratio of this.allowedRatios) {
      const distance = Math.abs(imageRatio - ratio[0] / ratio[1]);
      if (distance < minDistanceToRatio) {
        minDistanceToRatio = distance;
        bestRatio = ratio;
      }
    }

    return `Suggested Ratio: ${bestRatio[0]}:${bestRatio[1]}`;
  }

  protected computeRatio() {
    const file = this.filesToGetRatio[0];

    this.getImageDimensions(file)
      .then(({width, height}) => {
        console.log('Image width:', width);
        console.log('Image height:', height);

        this.notificationService.create(
          'success',
          'Suggested Ratio',
          this.getSuggestedAspectRatio(width, height),
          {nzDuration: 0, nzPlacement: 'top'},
        );
      })
      .catch((error) => {
        console.error('Error getting image dimensions:', error);
      });

    return false;
  }

  protected fileList: NzUploadFile[] = [];
  protected filesToGetRatio: NzUploadFile[] = [];

  protected pendingImageToExtractBackgroundDescription: string[] = [];
  protected previewVisible = false;
  protected previewImage: string | undefined = '';

  private readonly defaultGlobalSettings: GlobalSettings = {
    imageGenModelVersion: ImageGenModelVersionEnum.V_5,
    geminiModelVersion: GeminiModelVersionEnum.V_1_5,
    numberOfImages: this.defaultNumberOfImages,
  };

  protected editedGeminiModelVersion!: GeminiModelVersionEnum;
  protected editedImageGenModelVersion!: ImageGenModelVersionEnum;
  protected editedNumberOfImages!: number;

  protected globalSettings$: Observable<GlobalSettings>;

  protected listImageGenModelVersions: ImageGenModelVersionEnum[] =
    Object.values(ImageGenModelVersionEnum);

  protected listGeminiModelVersions: GeminiModelVersionEnum[] = Object.values(
    GeminiModelVersionEnum,
  );

  constructor(
    private readonly idbService: IDBService,
    private readonly router: Router,
    private readonly notificationService: NzNotificationService,
    private readonly apiService: ApiService,
    private readonly bls: BusinessLogicService,
  ) {
    this.globalSettings$ = this.loadInitialGlobalSettings().pipe(
      shareReplay(1), // Share the result among multiple subscribers
    );

    // adding the logic for displaying the name of the workspace
    // that is currently opened.
    this.router.events
      .pipe(filter((event: unknown) => event instanceof NavigationEnd))
      .subscribe(() => {
        // Logic to determine if the route you want is active
        console.log('router event');
        console.log(this.router.url);

        this.currentlyViewingSpecificWorkspace = this.router.isActive(
          '/workspace',
          false,
        );

        if (this.currentlyViewingSpecificWorkspace) {
          const workspaceId = router.url.split('/')?.pop() ?? '';
          console.log('workspaceId: ' + workspaceId);
          this.workspaceName$ = this.idbService.getWorkspaceName(workspaceId);
        } else {
          this.workspaceName$ = of('');
        }
      });
  }

  protected onImageGenModelVersionChange(newVersion: ImageGenModelVersionEnum) {
    this.editedImageGenModelVersion = newVersion;
  }

  protected onGeminiModelVersionChange(newVersion: GeminiModelVersionEnum) {
    this.editedGeminiModelVersion = newVersion;
  }

  protected onNumberOfImagesChange(newNumberOfImages: number) {
    this.editedNumberOfImages = newNumberOfImages;
  }

  private loadInitialGlobalSettings(): Observable<GlobalSettings> {
    return this.idbService.getGlobalSettings().pipe(
      takeUntilDestroyed(this.destroyRef), // Unsubscribe on destroy
      switchMap((settings: GlobalSettings | undefined) => {
        if (settings) {
          this.editedImageGenModelVersion = settings.imageGenModelVersion;
          this.editedGeminiModelVersion = settings.geminiModelVersion;
          // Since this field was added later on, earlier versions of the app
          // will not have it, adding default value to both edited and saved
          // values in this case.
          this.editedNumberOfImages =
            settings?.numberOfImages ?? this.defaultNumberOfImages;
          settings.numberOfImages =
            settings?.numberOfImages ?? this.defaultNumberOfImages;
          console.log('current settings: ' + JSON.stringify(settings));
          return of(settings);
        } else {
          this.editedImageGenModelVersion =
            this.defaultGlobalSettings.imageGenModelVersion;
          this.editedGeminiModelVersion =
            this.defaultGlobalSettings.geminiModelVersion;
          this.editedNumberOfImages = this.defaultGlobalSettings.numberOfImages;
          console.log('settings not present');
          return from(
            this.idbService.saveGlobalSettings(this.defaultGlobalSettings),
          ).pipe(map(() => this.defaultGlobalSettings));
        }
      }),
    );
  }

  protected openMainSettingsDrawer() {
    this.isMainSettingsDrawervisible = true;
  }

  protected saveEditedSettings(): void {
    const updatedSettings: GlobalSettings = {
      imageGenModelVersion: this.editedImageGenModelVersion,
      geminiModelVersion: this.editedGeminiModelVersion,
      numberOfImages: this.editedNumberOfImages,
    };
    console.log('updatedSettings: ' + JSON.stringify(updatedSettings));
    this.globalSettings$ = from(
      this.idbService.saveGlobalSettings(updatedSettings).pipe(
        takeUntilDestroyed(this.destroyRef),
        map(() => updatedSettings),
      ),
    );
    this.isMainSettingsDrawervisible = false;
  }

  protected cancelEditedSettings() {
    this.globalSettings$ = this.loadInitialGlobalSettings().pipe(
      shareReplay(1), // Reset edited values
    );
    this.isMainSettingsDrawervisible = false;
  }

  protected handleBackgroundDescriptionValidationCancel() {
    console.log('Button cancel clicked in the modal!');
    this.isBackgroundDescriptionModalVisible = false;
  }

  handlePreview = async (file: NzUploadFile): Promise<void> => {
    if (!file.url && !file['preview']) {
      file['preview'] = await getBase64(file.originFileObj!);
    }
    this.previewImage = file.url || file['preview'];
    this.previewVisible = true;
  };

  protected fileUploadChanged(event: NzUploadChangeParam) {
    if (event.type === 'success') {
      const imageToExtractBackgroundDescription = event.file;
      this.pendingImageToExtractBackgroundDescription.push(
        imageToExtractBackgroundDescription.response.url,
      );
    }
  }

  protected imageToReSizeUploadChanged(event: NzUploadChangeParam) {
    if (event.type === 'success') {
      const imageToReSize = event.file;
      this.getImageDimensions(imageToReSize)
        .then(({width, height}) => {
          console.log('Image width:', width);
          console.log('Image height:', height);
          if (this.initialWidth !== 1 && width !== this.initialWidth) {
            console.log('Images of different size detected');
            this.allImagesSameSize = false;
          }
          if (this.initialHeight !== 1 && height !== this.initialHeight) {
            console.log('Images of different size detected');
            this.allImagesSameSize = false;
          }
          if (!this.allImagesSameSize) {
            this.notificationService.create(
              'error',
              'Images Resize',
              'Cannot attach images with different sizes.',
              {nzDuration: 0, nzPlacement: 'top'},
            );
          }
          this.initialWidth = width;
          this.initialHeight = height;
          this.newWidth = Math.ceil(width / 2);
          this.newHeight = Math.ceil(height / 2);
        })
        .catch((error) => {
          console.error('Error getting image dimensions:', error);
        });
    }
  }

  protected isResized(): boolean {
    return (
      this.newWidth !== this.initialWidth &&
      this.newHeight !== this.initialHeight
    );
  }

  protected onUpdateHeight(width: number) {
    this.newHeight = Math.ceil(
      (width / this.initialWidth) * this.initialHeight,
    );
    this.newWidth = Math.ceil(width);
  }

  protected onUpdateWidth(height: number) {
    this.newWidth = Math.ceil(
      (height / this.initialHeight) * this.initialWidth,
    );
    this.newHeight = Math.ceil(height);
  }

  // beforeResize = async (files: NzUploadFile[]): boolean => {
  //   if (files) {
  //     const reader = new FileReader();
  //     reader.onload = (event) => {
  //       const img = new Image();
  //       img.onload = () => {
  //         this.newWidth = img.width;
  //         this.newHeight = img.height;
  //       };
  //       img.src = event.target?.result as string;
  //     };
  //     reader.readAsDataURL(files[0].originFileObj!);
  //   }
  //   return true;
  // };

  // beforeResize = (
  //   file: NzUploadFile,
  //   fileList: NzUploadFile[],
  // ): Observable<boolean> =>
  //   new Observable((observer: Observer<boolean>) => {
  //     const img = new Image();
  //     img.src = URL.createObjectURL(file.originFileObj!);

  //     img.onload = () => {
  //       this.initialWidth = img.width;
  //       this.initialHeight = img.height;
  //       observer.next(true);
  //       observer.complete();
  //     };

  //     img.onerror = () => {
  //       observer.error('File could not be read.');
  //     };
  //   });

  // handlePreviewForResize = async (file: NzUploadFile): Promise<void> => {
  //   if (!file.url && !file['preview']) {
  //     file['preview'] = await getBase64(file.originFileObj!);
  //   }
  //   this.previewImage = file.url || file['preview'];
  //   this.getImageDimensions(file)
  //     .then(({width, height}) => {
  //       console.log('Image width:', width);
  //       console.log('Image height:', height);

  //       this.initialWidth = width;
  //       this.initialHeight = height;
  //     })
  //     .catch((error) => {
  //       console.error('Error getting image dimensions:', error);
  //     });
  //   this.previewVisible = true;
  // };

  removeImageToExtractBackgroundDescription = (
    imageToExtractBackgroundDescription: NzUploadFile,
  ): boolean => {
    console.log('removeForegroundImage');
    console.log(imageToExtractBackgroundDescription);

    if (imageToExtractBackgroundDescription.response?.url) {
      this.apiService
        .deleteUploadedFile(imageToExtractBackgroundDescription.response.url)
        .subscribe({
          error: (err) => {
            console.log(err);
          },
        });

      this.pendingImageToExtractBackgroundDescription =
        this.pendingImageToExtractBackgroundDescription.filter(
          (image) => image !== imageToExtractBackgroundDescription.response.url,
        );
    }

    return true;
  };

  /**
   * Return true only if there are some files and they are all
   * uploaded without error.
   */
  protected wereAllFilesUploaded(
    fileList: NzUploadFile[] = this.fileList,
  ): boolean {
    // console.log('checkAllFilesWereDowloaded');
    // console.log(this.fileList);

    return (
      fileList.length > 0 &&
      fileList.every(
        (file) =>
          file.percent === 100 &&
          file.status !== 'error' &&
          file['isUploading'] === false,
      )
    );
  }

  protected extractBackgroundDescription(): void {
    //TODO: CALL API HERE

    this.idbService
      .getGlobalSettings()
      .pipe(
        map((settings: GlobalSettings | undefined) => {
          if (settings) {
            console.log('Global settings retrieved:', settings);
            return settings;
          } else {
            // Handle the case where settings are undefined, maybe return default values?
            console.error('Global settings not found!');

            return null;
          }
        }),
        switchMap((settings: GlobalSettings | null) => {
          console.log('extractBackgroundDescription');
          return this.bls.describeImageBackground(
            this.pendingImageToExtractBackgroundDescription[0],
            settings,
          );
        }),
      )
      .subscribe({
        next: (result) => {
          // TODO: if sucessful pass the image background description to the
          // notification.
          this.notificationService.create(
            'success',
            'Image Background Description',
            result,
            {nzDuration: 0, nzPlacement: 'top'},
          );
        },
        error: (error) => {
          //TODO: if not successful pass the error to the notification.
          this.notificationService.create(
            'error',
            'Image Background Description',
            error,
            {nzDuration: 0, nzPlacement: 'top'},
          );
        },
      });
  }
}

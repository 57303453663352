import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {from, Observable, of, switchMap} from 'rxjs';
import {GlobalSettings} from '../models/global-settings';

function clickDownloadLink(url: string) {
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = url.split('/').pop() ?? 'not_found.png';
  document.body.appendChild(a);
  a.click();
}

/** API Services. */
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}

  downloadImage(url: string): Observable<any> {
    return from(fetch(url)).pipe(
      switchMap((resp) => from(resp.blob())),
      switchMap((blob) => {
        const localBlobUrl = window.URL.createObjectURL(blob);
        clickDownloadLink(localBlobUrl);
        window.URL.revokeObjectURL(localBlobUrl);
        return of();
      }),
    );
  }

  downloadZip(url: string): Observable<any> {
    return of(url).pipe(
      switchMap(() => {
        clickDownloadLink(url);
        return of();
      }),
    );
  }

  deleteUploadedFile(backgroundUrl: string): Observable<any> {
    const params: any = {
      url: backgroundUrl,
    };

    return this.http.delete('/api/v2/upload', {
      params,
      withCredentials: true,
    });
  }

  generateContent(
    prompt: string,
    modelName: string,
    url: string | null = null,
  ): Observable<string> {
    let params: any = {
      prompt,
    };
    if (url !== null) {
      params = {...params, image_url: url, model_name: modelName};
    }
    return this.http.get('/api/v2/generate-content', {
      params,
      responseType: 'text',
      withCredentials: true,
    });
  }

  generateImage(
    folderName: string,
    prompt: string,
    transparency: number,
    safetyFilterLevel: string,
    language: string,
    personGenerationSetting: string,
    negativePrompt: string,
    aspectRatio: string,
    directGenreation: boolean,
    useSeed: boolean,
    settings: GlobalSettings | null,
  ) {
    const params: any = {
      prompt,
      folder_name: folderName,
      direct_generation: directGenreation,
      transparency_level: transparency,
      safety_filter_level: safetyFilterLevel,
      language,
      person_generation: personGenerationSetting,
      negative_prompt: negativePrompt,
      seed: 1,
      aspect_ratio: aspectRatio,
      gemini_model_name: settings!.geminiModelVersion,
      imagen_model_name: settings!.imageGenModelVersion,
      number_of_images: 1,
      use_seed: useSeed,
    };
    return this.http.get<{prompt: string; urls: string[]}>(
      '/api/v2/generate-image',
      {
        params,
        withCredentials: true,
      },
    );
  }

  overlayImage(
    folderName: string,
    foregroundUrl: string,
    backgroundUrl: string,
  ) {
    const params: any = {
      folder_name: folderName,
      foreground_url: foregroundUrl,
      background_url: backgroundUrl,
    };
    return this.http.get('/api/v2/overlay-image', {
      params,
      withCredentials: true,
    });
  }

  annotateImage(imageUrl: string) {
    const params: any = {
      image_url: imageUrl,
    };
    return this.http.get('/api/v2/annotate-image', {
      params,
      withCredentials: true,
    });
  }

  resizeImages(urls: string[], width: number, height: number) {
    const body = {
      urls,
      width,
      height,
    };
    return this.http.post('/api/v2/resize-images', body, {
      withCredentials: true,
    });
  }
}
